import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

import * as Layout from '../layouts/layout'

const Content = styled.div`
  border: 1px solid #eaecee;
  padding: 30px 45px;
  margin-bottom: 1rem;
`

const Header = styled.div`
  border: 1px solid #eaecee;
  padding: 30px 45px;
  margin-bottom: 1rem;
`

const PostList = props => {
  const { posts, title } = props

  return (
    <Layout.Section>
      <Layout.Container>
        <Header>
          <h1 className="has-text-weight-bold is-size-2">{title}</h1>
        </Header>
        {posts.map(({ node: post }) => (
          <Content key={post.id}>
            <p>
              <Link className="has-text-primary" to={`/news/${post.slug}`}>
                {post.title}
              </Link>
              <span> &bull; </span>
              <small>
                {post.date} - posted by{' '}
                <Link to={`/author/${post.author.slug}`}>
                  {post.author.name}
                </Link>
              </small>
            </p>
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: post.excerpt.replace(/<p class="link-more.*/, ''),
                }}
              />
              <Link className="button is-small" to={`/news/${post.slug}`}>
                Keep Reading →
              </Link>
            </div>
          </Content>
        ))}
      </Layout.Container>
    </Layout.Section>
  )
}

export default PostList

PostList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    title
    excerpt
    author {
      name
      slug
      avatar_urls {
        wordpress_48
      }
    }
    date(formatString: "MMMM DD, YYYY")
    slug
  }
`
